// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import _sortBy from "lodash/sortBy";
import _zip from "lodash/zip";
import RBush from "rbush";
import Two from "two.js";
import ZUI from "two.js/extras/jsm/zui.js";
import { calculateTextDimensions } from "utils/dom";

import {
  Ipdb3dModel,
  Lsd,
  ScreenSize,
  XdaData,
  XdaLayoutOptions,
  XdaSceneRect
} from "models/xdaData";

import convertHexToRGBA from "components/vis/util/convertHexToRgba";
import getDataToScreenRatio from "components/vis/util/getDataToScreenRatio";
import getDistanceBetweenPoints from "components/vis/util/getDistanceBetweenPoints";

import { IScreenshotSettings } from "../../models/screenshot";

const TEXT_PADDING = 10;
// offset for displaying measurement so it doesn't cover the dot
const Y_OFFSET = 15;

const fitPointToView = (
  x: number,
  y: number,
  sceneWidth: number,
  sceneHeight: number,
  options: XdaLayoutOptions,
  screenSize: ScreenSize
) => {
  const size = screenSize;
  const actualWidth = size.width - options.grid.left - options.grid.right;
  const actualHeight = size.height - options.grid.top - options.grid.bottom;
  const px = options.grid.left + (x / sceneWidth) * actualWidth;
  const py = options.grid.top + (y / sceneHeight) * actualHeight;
  return [px, py];
};

function drawYAxis(
  context,
  data: XdaData,
  settings: IScreenshotSettings,
  options: XdaLayoutOptions,
  screenSize: ScreenSize,
  screenshotOverlayVisible: boolean,
  showRelativeDepth: boolean,
  showTVDSSDepth: boolean
) {
  const rect = data?.rect;
  const sceneWidth = rect.width;
  const sceneHeight = rect.height;

  drawYAxisLine(context, sceneWidth, sceneHeight, options, screenSize);
  drawYAxisTicks(
    context,
    settings,
    rect.miny,
    rect.maxy,
    sceneWidth,
    sceneHeight,
    options,
    screenSize,
    screenshotOverlayVisible,
    showRelativeDepth,
    showTVDSSDepth
  );
}

function drawXAxis(
  context,
  settings: IScreenshotSettings,
  sceneWidth,
  sceneHeight,
  data: XdaData,
  options: XdaLayoutOptions,
  screenSize: ScreenSize,
  screenshotOverlayVisible: boolean,
  showLabels: boolean
) {
  drawXAxisLine(context, sceneWidth, sceneHeight, options, screenSize);
  if (showLabels) {
    drawXAxisTicks(
      context,
      settings,
      data,
      options,
      screenSize,
      screenshotOverlayVisible
    );
  }
}

function drawAAPrimeLabel(
  context,
  rect: XdaSceneRect,
  options: XdaLayoutOptions,
  screenSize: ScreenSize,
  settings: IScreenshotSettings,
  showRelativeDepth: boolean,
  showTVDSSDepth: boolean
) {
  let [x, y] = fitPointToView(0, 0, rect.width, rect.height, options, screenSize);
  x -= options.axisGap;
  y -= options.axisGap;
  context.makeText("A", x + 10, y - 5);
  const [width] = fitPointToView(
    rect.width * options.scale,
    rect.height,
    rect.width,
    rect.height,
    options,
    screenSize
  );
  context.makeText("A'", x + width - options.axisGap * 3, y - 5);
  if (showTVDSSDepth) {
    let gap = 0;
    if (showRelativeDepth) {
      gap = calculateTextDimensions(
        "00000",
        settings?.gridLabelsFontSize,
        "sans-serif"
      ).width;
    }
    context.makeText("TVD SS", x + width - options.axisGap * 3 + gap + 30, y - 5, {
      size: 10
    });
  }
}

function drawBoundingBox(
  context,
  rect: XdaSceneRect,
  options: XdaLayoutOptions,
  screenSize: ScreenSize
) {
  let [x, y] = fitPointToView(0, 0, rect.widthScaled, rect.height, options, screenSize);
  x -= options.axisGap;
  y -= options.axisGap;
  let [width, height] = fitPointToView(
    rect.widthScaled,
    rect.height,
    rect.width,
    rect.height,
    options,
    screenSize
  );
  height -= y - options.axisGap;
  width -= x - options.axisGap;
  const rectangle = context.makeRectangle(x + width / 2, y + height / 2, width, height);
  rectangle.linewidth = 1;
  rectangle.stroke = "lightgray";
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function drawAxis(
  context,
  data: XdaData,
  settings: IScreenshotSettings,
  options: XdaLayoutOptions,
  screenSize: ScreenSize,
  showGrid: boolean,
  screenshotOverlayVisible: boolean,
  showRelativeDepth: boolean,
  showTVDSSDepth: boolean
): void {
  const rect = data?.rect;
  if (!rect) {
    return;
  }
  drawBoundingBox(context, rect, options, screenSize);
  drawAAPrimeLabel(
    context,
    rect,
    options,
    screenSize,
    settings,
    showRelativeDepth,
    showTVDSSDepth
  );
  if (showGrid || showTVDSSDepth || showRelativeDepth) {
    drawXAxis(
      context,
      settings,
      rect.width,
      rect.height,
      data,
      options,
      screenSize,
      screenshotOverlayVisible,
      showGrid
    );
    drawYAxis(
      context,
      data,
      settings,
      options,
      screenSize,
      screenshotOverlayVisible,
      showRelativeDepth,
      showTVDSSDepth
    );
  }
}

let guideLinesGroup = undefined;

export function drawGuideLines(data: XdaData, centroid, context, options, screenSize) {
  const rect = data.rect;
  const [x, y] = fitPointToView(
    centroid.x,
    centroid.y,
    rect.width,
    rect.height,
    options,
    screenSize
  );

  const centroids = data.centroids.sort((c1, c2) => c1.x - c2.x);
  const guideLines = [];
  // keeps track of the x-position when the last label was positioned,
  // with the index corresponding to the offset (index 0 = no offset, index 1 = 1x offset, etc.)
  const prevTopPos = [];
  const prevBotPos = [];
  for (const other of centroids) {
    if (centroid === other) {
      continue;
    }
    const [x2, y2] = fitPointToView(
      other.x,
      other.y,
      rect.width,
      rect.height,
      options,
      screenSize
    );
    let offset = 0;
    if (other.y > centroid.y) {
      let i = 0;
      // loop to find the appropriate offset
      while (i < prevTopPos.length) {
        if (other.x - prevTopPos[i] > rect.width / 20) {
          break;
        }
        i++;
      }
      prevTopPos[i] = other.x;
      offset = i * -10 + -9;
    } else {
      let i = 0;
      while (i < prevBotPos.length) {
        if (other.x - prevBotPos[i] > rect.width / 20) {
          break;
        }
        i++;
      }
      prevBotPos[i] = other.x;
      offset = i * 10 + 9;
    }
    const diffX = Math.round(Math.abs(other.x - centroid.x));
    const diffY = Math.abs(other.y - centroid.y);
    const line = context.makeLine(x, y, x2, y);
    line.linewidth = 2;
    line.stroke = "gray";
    line.dashes = [2, 1];
    const line2 = context.makeLine(x2, y, x2, y2);
    line2.linewidth = 2;
    line2.dashes = [2, 1];
    line2.stroke = "gray";
    guideLines.push(line, line2);
    context.update();
    line._renderer.elem.style.pointerEvents = "none";
    line2._renderer.elem.style.pointerEvents = "none";
    if (diffX > 10) {
      const textX = context.makeText(diffX, x2, y + offset);
      textX.size = 9;
      textX.fill = "purple";
      guideLines.push(textX);
      context.update();
      textX._renderer.elem.style.pointerEvents = "none";
    }
    if (diffY > 10) {
      const textY = context.makeText(diffY, x2, y2 + offset);
      textY.size = 9;
      guideLines.push(textY);
      context.update();
      textY._renderer.elem.style.pointerEvents = "none";
    }
  }
  if (!guideLinesGroup) {
    guideLinesGroup = context.makeGroup();
  }
  guideLinesGroup.add(guideLines);
  context.update();
}

function drawYAxisLine(
  context,
  sceneWidth: number,
  sceneHeight: number,
  options: XdaLayoutOptions,
  screenSize: ScreenSize
) {
  const scaledWidth = options.scale * sceneWidth;
  let [tx, ty] = fitPointToView(0, 0, sceneWidth, sceneHeight, options, screenSize);
  let [bx, by] = fitPointToView(
    0,
    sceneHeight,
    scaledWidth,
    sceneHeight,
    options,
    screenSize
  );
  tx -= options.axisGap;
  ty -= options.axisGap;
  bx -= options.axisGap;
  by += options.axisGap;
  const line = context.makeLine(tx, ty, bx, by);
  line.linewidth = 3;
}

function drawXAxisLine(
  context,
  sceneWidth,
  sceneHeight,
  options: XdaLayoutOptions,
  screenSize
) {
  let [tx, ty] = fitPointToView(
    0,
    sceneHeight,
    sceneWidth * options.scale,
    sceneHeight,
    options,
    screenSize
  );
  let [bx, by] = fitPointToView(
    sceneWidth * options.scale,
    sceneHeight,
    sceneWidth,
    sceneHeight,
    options,
    screenSize
  );
  tx -= options.axisGap;
  ty += options.axisGap;
  bx += options.axisGap;
  by += options.axisGap;
  const line = context.makeLine(tx, ty, bx, by);
  line.linewidth = 3;
}

function drawYAxisTicks(
  context,
  settings: IScreenshotSettings,
  miny: number,
  maxy: number,
  sceneWidth: number,
  sceneHeight: number,
  options: XdaLayoutOptions,
  screenSize: ScreenSize,
  screenshotOverlayVisible: boolean,
  showRelativeDepth: boolean,
  showTVDSSDepth: boolean
) {
  const numberOfTicks = 10.0;
  const originalMinY = miny;
  const originalMaxY = maxy;
  const startY = Math.sign(originalMaxY) * Math.floor(Math.abs(originalMaxY) / 10) * 10;
  const endY = Math.sign(originalMinY) * Math.ceil(Math.abs(originalMinY) / 10) * 10;
  const offset = originalMaxY - startY;
  let tickSize = Math.floor((startY - endY) / numberOfTicks);
  tickSize = Math.ceil(Math.abs(tickSize) / 10) * 10;
  const dividerCoords = [];
  for (let i = 0; i <= numberOfTicks; i++) {
    const stepSize = i * tickSize;
    const value = originalMaxY - i * tickSize;
    const relativeValue = 0 - i * tickSize;
    const [x, y] = fitPointToView(
      sceneWidth * options.scale,
      stepSize + offset,
      sceneWidth,
      sceneHeight,
      options,
      screenSize
    );
    if (maxy - value < miny - 10) {
      continue;
    }
    if (y > sceneHeight - offset && i > 7) {
      dividerCoords.push(y);
      //show at least 7 grid lines before checking if it's outside
      break;
    }

    // calculate how much of a gap to leave between the relative and TVDSS depth labels
    let gap = 0;
    if (showTVDSSDepth && showRelativeDepth) {
      gap = calculateTextDimensions(
        "00000",
        settings?.gridLabelsFontSize,
        "sans-serif"
      ).width;
    }

    if (showTVDSSDepth) {
      const text = context.makeText(Math.round(value), x + gap + 25, y);
      text.size =
        screenshotOverlayVisible && settings?.gridLabelsFontSize
          ? settings.gridLabelsFontSize
          : 10;
      text.alignment = "left";
    }

    if (showRelativeDepth) {
      const text1 = context.makeText(Math.round(relativeValue), x + 25, y);
      text1.size =
        screenshotOverlayVisible && settings?.gridLabelsFontSize
          ? settings.gridLabelsFontSize
          : 10;
      text1.alignment = "left";
    }
    // store where to place the divider line
    if (i === 0) {
      dividerCoords.push(x + gap + 20, y - 10);
    } else if (i === numberOfTicks) {
      dividerCoords.push(y);
    }

    // eslint-disable-next-line prefer-const
    let [linex0, liney0] = fitPointToView(
      0,
      stepSize + offset,
      sceneWidth,
      sceneHeight,
      options,
      screenSize
    );
    linex0 -= options.axisGap - 1;
    // eslint-disable-next-line prefer-const
    let [linex1, liney1] = fitPointToView(
      sceneWidth * options.scale,
      stepSize + offset,
      sceneWidth,
      sceneHeight,
      options,
      screenSize
    );
    linex1 += options.axisGap + 2;
    const axisLine = context.makeLine(linex0, liney0, linex1, liney1);
    axisLine.linewidth = 1;
    axisLine.stroke = "lightgray";
  }

  if (showRelativeDepth && showTVDSSDepth) {
    const dividerLine = context.makeLine(
      dividerCoords[0],
      dividerCoords[1],
      dividerCoords[0],
      dividerCoords[2]
    );
    dividerLine.linewidth = 0.5;
  }
}

function drawXAxisTicks(
  context,
  settings,
  data,
  options,
  screenSize,
  screenshotOverlayVisible
) {
  const width = data.rect.width;
  const height = data.rect.height;
  const scaledHeight = data.rect.heightScaled;
  const majorTickSteps = Math.floor(data.rect.width / 10 / 10.0) * 10;
  for (let i = 0; i <= 10; i++) {
    const value = i * majorTickSteps * options.scale;
    let [x, y] = fitPointToView(value, height, width, height, options, screenSize);
    y += options.axisGap + 20;
    x -= options.axisGap;
    const text = context.makeText(Math.round(value / options.scale), x, y);
    text.size =
      screenshotOverlayVisible && settings?.gridLabelsFontSize
        ? settings.gridLabelsFontSize
        : 10;

    let [linex0, liney0] = fitPointToView(value, 0, width, height, options, screenSize);
    liney0 -= options.axisGap;
    linex0 -= options.axisGap;
    let [linex1, liney1] = fitPointToView(
      value,
      scaledHeight,
      width,
      height,
      options,
      screenSize
    );
    linex1 -= options.axisGap;
    liney1 += options.axisGap + 10;

    const axisLine = context.makeLine(linex0, liney0, linex1, liney1);
    axisLine.linewidth = 1;
    axisLine.stroke = "lightgray";
  }
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function drawCentroid(
  data: XdaData,
  settings,
  context,
  options,
  screenSize,
  showData: boolean, //whether to show the data on the well or not
  screenshotOverlayVisible
): void {
  const rect = data.rect;
  const colorDict = {};
  const randomColor = [
    "#a50026",
    "#d73027",
    "#f46d43",
    "#67001f",
    "#01665e",
    "#762a83",
    "#c51b7d",
    "#8c510a",
    "#74add1",
    "#4575b4",
    "#313695",
    "#542788",
    "#00441b"
  ];
  const colorLen = randomColor.length;
  for (let i = 0; i < data.centroids.length; i++) {
    const centroid = data.centroids[i];
    const [x, y] = fitPointToView(
      centroid.x,
      centroid.y,
      rect.width,
      rect.height,
      options,
      screenSize
    );
    const circle = context.makeCircle(x, y, 3);
    circle.fill = showData ? `${randomColor[i % colorLen]}` : "#212121";
    colorDict[data.ids[i].unique_id] = circle.fill;
  }
  if (showData) {
    drawValueLabels(
      data,
      settings,
      context,
      options,
      screenSize,
      colorDict,
      screenshotOverlayVisible
    );
  }
  context.update();
}

function drawValueLabels(
  data: XdaData,
  settings,
  context,
  options,
  screenSize,
  colorDict: { [id: string]: string },
  screenshotOverlayVisible
) {
  const wellData = data.well_data;
  if (!wellData || wellData?.length === 0) {
    return;
  }
  const yOffset = 9;

  const widthPerChar = 6;
  const dataZipped = _zip(data.centroids, data.ids, data.well_data);

  const textSize =
    screenshotOverlayVisible && settings?.topsLabelsFontSize
      ? settings.dataLabelsFontSize
      : 9;

  const rect = data.rect;
  const tree = new RBush();

  const sortedByLeftToRight = _sortBy(dataZipped, [(item) => item[0].x]);
  for (const item of sortedByLeftToRight) {
    const [x, y] = fitPointToView(
      item[0].x,
      item[0].y,
      rect.width,
      rect.height,
      options,
      screenSize
    );
    const textStyle = {
      size: textSize,
      fill: colorDict[item[1].unique_id]
    };
    const value = item[2][3];
    if (!value || value === "n/a") {
      continue;
    }
    const valueWidth = (value.length * widthPerChar) / 2.0;
    let box = {
      minX: x + widthPerChar,
      minY: y - yOffset / 2,
      maxX: x + widthPerChar + valueWidth * 2,
      maxY: y + yOffset / 2,
      value
    };
    let i = 0;
    let xOffSet = 0;
    while (tree.search(box).length > 0) {
      i += 1;
      xOffSet = i * 3;
      box = {
        minX: x + widthPerChar + xOffSet, //increment to the right each time
        minY: y - yOffset / 2,
        maxX: x + widthPerChar + valueWidth * 2 + xOffSet,
        maxY: y + yOffset / 2,
        value
      };
      if (i > 10) {
        break;
      }
    }
    if (i > 10) {
      //not possible to find collision free within 10 pass
      continue;
    }
    tree.insert(box);
    context.makeText(
      value,
      box.minX + (box.maxX - box.minX) / 2,
      box.minY + (box.maxY - box.minY) / 2,
      textStyle
    );
  }

  const sortedByRightToLeft = sortedByLeftToRight.reverse();
  for (const item of sortedByRightToLeft) {
    const [x, y] = fitPointToView(
      item[0].x,
      item[0].y,
      rect.width,
      rect.height,
      options,
      screenSize
    );
    const textStyle = {
      size: textSize,
      fill: colorDict[item[1].unique_id]
    };
    const value = item[2][1];
    if (!value || value === "n/a") {
      continue;
    }
    const halfWidthOfText = (value.length * 5) / 2.0;
    let box = {
      minX: x - widthPerChar - halfWidthOfText * 2,
      minY: y - yOffset / 2,
      maxX: x - widthPerChar,
      maxY: y + yOffset / 2,
      value
    };
    let i = 0;
    let collisionOffset = 0;
    const spacing = 0;
    let collisionOffsetY = 0;
    while (tree.search(box).length > 0) {
      i += 1;
      collisionOffset = i * 2;
      collisionOffsetY = i * 2;
      box = {
        minX: x - spacing - halfWidthOfText * 2 - collisionOffset, //increment to the right each time
        minY: y - yOffset / 2 + collisionOffsetY,
        maxX: x - spacing - collisionOffset,
        maxY: y + yOffset / 2 + collisionOffset,
        value
      };
      if (i > 10) {
        break;
      }
    }
    if (i > 10) {
      //not possible to find collision free within 10 pass
      continue;
    }
    tree.insert(box);
    context.makeText(
      value,
      box.minX + (box.maxX - box.minX) / 2,
      box.minY + (box.maxY - box.minY) / 2,
      textStyle
    );
  }

  const sortedByTopToBottom = _sortBy(dataZipped, [(item) => item[0].y]);
  for (const item of sortedByTopToBottom) {
    const [x, y] = fitPointToView(
      item[0].x,
      item[0].y,
      rect.width,
      rect.height,
      options,
      screenSize
    );
    const value = item[2][0];
    if (!value || value === "n/a") {
      continue;
    }
    const textStyle = {
      size: textSize,
      fill: colorDict[item[1].unique_id]
    };
    const halfWidthOfText = (value.length * 5) / 2.0;
    const ySpacing = 4; //y spacing so that it doesn't touch the dot
    let box = {
      minX: x - halfWidthOfText,
      minY: y + ySpacing,
      maxX: x + halfWidthOfText,
      maxY: y + ySpacing + yOffset,
      value
    };
    let i = 0;
    let collisionOffset = 0;
    let collisionOffsetY = 0;
    while (tree.search(box).length > 0) {
      i += 1;
      collisionOffset = i * 4;
      collisionOffsetY = i * 4;
      box = {
        minX: x - halfWidthOfText, //increment to the right each time
        minY: y + ySpacing + collisionOffsetY,
        maxX: x + halfWidthOfText,
        maxY: y + ySpacing + yOffset + collisionOffset,
        value
      };
      if (i > 10) {
        break;
      }
    }
    if (i > 10) {
      //not possible to find collision free within 10 pass
      continue;
    }
    tree.insert(box);
    context.makeText(
      value,
      box.minX + (box.maxX - box.minX) / 2,
      box.minY + (box.maxY - box.minY) / 2,
      textStyle
    );
  }
  const sortedByBottomToTop = sortedByTopToBottom.reverse();
  for (const item of sortedByBottomToTop) {
    const [x, y] = fitPointToView(
      item[0].x,
      item[0].y,
      rect.width,
      rect.height,
      options,
      screenSize
    );
    const value = item[2][2];
    if (!value || value === "n/a") {
      continue;
    }
    const textStyle = {
      size: textSize,
      fill: colorDict[item[1].unique_id]
    };
    const halfWidthOfText = (value.length * 5) / 2.0;
    const ySpacing = 4; //y spacing so that it doesn't touch the dot
    let box = {
      minX: x - halfWidthOfText,
      minY: y - ySpacing - yOffset,
      maxX: x + halfWidthOfText,
      maxY: y - ySpacing,
      value
    };
    let i = 0;
    let collisionOffset = 0;
    let collisionOffsetY = 0;
    while (tree.search(box).length > 0) {
      i += 1;
      collisionOffset = i * 2;
      collisionOffsetY = i * 2;
      box = {
        minX: x - halfWidthOfText, //increment to the right each time
        minY: y - ySpacing - yOffset - collisionOffset,
        maxX: x + halfWidthOfText,
        maxY: y - ySpacing - collisionOffsetY,
        value
      };
      if (i > 10) {
        break;
      }
    }
    if (i > 10) {
      //not possible to find collision free within 10 pass
      continue;
    }
    tree.insert(box);
    // context.makeRectangle(box.minX + ((box.maxX - box.minX) / 2), box.minY + (box.maxY - box.minY) / 2 - 1,
    //   (box.maxX - box.minX) + 5, box.maxY - box.minY + 2);
    context.makeText(
      value,
      box.minX + (box.maxX - box.minX) / 2,
      box.minY + (box.maxY - box.minY) / 2,
      textStyle
    );
  }
}

export function drawIpdb3dModel(
  data: XdaData,
  context,
  options,
  screenSize,
  getColor: (ipdb: Ipdb3dModel) => { value: number; color: string }
) {
  if (!data.ipdb3d_boxes) {
    return;
  }
  const rect = data.rect;
  const rectangles = [];
  for (const cell of data.ipdb3d_boxes.sort((a, b) => {
    if (Math.abs(a.left.x - b.left.x) < 1e-10) {
      return a.top - b.top;
    }
    return a.left.x - b.left.x;
  })) {
    const [minX, maxY] = fitPointToView(
      Math.max(0, cell.left.x),
      cell.top,
      rect.width,
      rect.height,
      options,
      screenSize
    );
    const [maxX, minY] = fitPointToView(
      cell.right.x,
      cell.bot,
      rect.width,
      rect.height,
      options,
      screenSize
    );
    const width = maxX - minX;
    const height = maxY - minY;
    const rectangle = context.makeRectangle(
      minX + width / 2,
      Math.min(maxY, minY) - height / 2.0,
      width,
      height
    );
    rectangles.push(rectangle);
    rectangle.linewidth = 0;
    const { value, color } = getColor(cell);
    rectangle.fill = color;
    rectangle.value = value;
  }
  return rectangles;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function drawZoneLine(
  data,
  settings,
  context,
  options,
  screenSize,
  screenshotOverlayVisible
): void {
  const textSize =
    screenshotOverlayVisible && settings?.topsLabelsFontSize
      ? settings.topsLabelsFontSize
      : 9;

  function extracted(lsds: Lsd[], color: string) {
    let i = 0;
    for (const lsd of lsds) {
      let maxy = -10000;
      let miny = 100000;
      let minx = 100000;
      let maxx = -10000;

      // track bottom of previous text
      let previoustextbot = 0;

      for (const zone of lsd.zones) {
        const line = zone.line;
        const [sx, sy] = fitPointToView(
          i === 0 ? 0 : line.start.x,
          line.start.y,
          rect.width,
          rect.height,
          options,
          screenSize
        );
        const [ex, ey] = fitPointToView(
          line.end.x,
          line.end.y,
          rect.width,
          rect.height,
          options,
          screenSize
        );

        maxy = Math.max(sy, maxy);
        let zoneLine = null;
        if (i === 0) {
          zoneLine = context.makeLine(sx - options.axisGap, sy, ex, ey);

          // first label
          if (previoustextbot === 0) {
            const text = context.makeText(zone.zone, sx - options.axisGap - 5, sy - 5);
            text.size = textSize;
            text.fill = color;
            text.alignment = "right";
            previoustextbot = sy;
            // if label is too close to the previous one
          } else if (sy - 7 < previoustextbot) {
            const text = context.makeText(
              zone.zone,
              sx - options.axisGap - 5,
              previoustextbot + 5
            );
            text.fill = color;
            text.size = textSize;
            text.alignment = "right";
            previoustextbot = previoustextbot + 10;
            // if label is not too close
          } else {
            const text = context.makeText(zone.zone, sx - options.axisGap - 5, sy - 5);
            text.size = textSize;
            text.alignment = "right";
            text.fill = color;
            previoustextbot = sy;
          }
        } else {
          zoneLine = context.makeLine(sx, sy, ex, ey);
        }
        zoneLine.width = 2;
        zoneLine.stroke = color;
        minx = Math.min(sx, minx);
        maxx = Math.max(ex, maxx);
        maxy = Math.max(maxy, sy);
        miny = Math.min(miny, sy);
      }
      if (maxx > 0) {
        context.makeLine(minx, miny, minx, maxy);
        context.makeLine(maxx, miny, maxx, maxy);
      }
      i++;
    }
  }

  const rect = data.rect;
  if (data.otherLsds?.length > 0) {
    extracted(data.otherLsds, "lightgray");
  }
  extracted(data.lsds, "black");
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const drawShape = (
  data: XdaData,
  context,
  selectedWells,
  options: XdaLayoutOptions,
  screenSize: ScreenSize,
  onShapeEntered,
  onShapeLeave,
  highlightWells,
  pathsRef,
  showMeasurement,
  showOverlap
): void => {
  if (!selectedWells) {
    return;
  }
  let i = 0;
  const rect = data.rect;
  pathsRef.current = [];
  const paths = [];
  const pattern = new Two({
    type: Two.Types.canvas,
    width: 32,
    height: 32
  });
  //draw polygons not in well list
  //draw before so other polygon can be above the ones not in the well list
  const line = pattern.makeLine(pattern.width, 0, 0, pattern.height);
  line.stroke = "grey";
  pattern.update();
  for (const poly of data.polygons_not_in_list) {
    const anchors = [];
    for (const point of poly.points) {
      const a = fitPointToView(
        point.x,
        point.y,
        rect.width,
        rect.height,
        options,
        screenSize
      );
      anchors.push(new Two.Anchor(a[0], a[1]));
    }
    const path = context.makePath(anchors, false);
    path.linewidth = 1;
    const texture = context.makeTexture(pattern.renderer.domElement);
    texture.scale = 1;
    texture.repeat = "repeat";
    texture.stroke = "green";
    path.fill = texture;
  }

  for (const poly of data.polygons) {
    const anchors = [];
    for (const point of poly) {
      const a = fitPointToView(
        point.x,
        point.y,
        rect.width,
        rect.height,
        options,
        screenSize
      );
      anchors.push(new Two.Anchor(a[0], a[1]));
    }
    const path = context.makePath(anchors, false);

    const id = data.ids[i++];

    path.linewidth = 3;
    path.stroke = "#212121";
    path.id = id.unique_id;
    if (id.unique_id in selectedWells) {
      const well = selectedWells[id.unique_id];
      if (id.unique_id === highlightWells) {
        path.fill = well.color;
      } else if (showOverlap) {
        path.fill = convertHexToRGBA(well.color, 0.3);
      } else {
        path.fill = "rgba(0,0,0,0)";
      }
      path.stroke = well.color;
    }

    paths.push(path);
    pathsRef.current.push(path);
  }

  context.update();
  i = 0;
  for (const path of paths) {
    const centroid = data.centroids[i];
    const wellData = data.well_data[i];
    const id = data.ids[i];
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    path._renderer.elem.addEventListener("mouseenter", (evt) => {
      // off when measurement tool is on
      if (!showMeasurement) {
        drawGuideLines(data, centroid, context, options, screenSize);
        onShapeEntered(evt, path, id, selectedWells, wellData);
        evt.preventDefault();
      }
    });
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    path._renderer.elem.addEventListener("mouseleave", (evt) => {
      // off when measurement tool is on
      if (!showMeasurement) {
        onShapeLeave(evt, path, id);
        if (guideLinesGroup) {
          context.remove(guideLinesGroup);
          guideLinesGroup = undefined;
        }
        evt.preventDefault();
      }
    });
    i++;
  }
};

export const handleMeasurementTool = (
  measurementDataRef,
  showMeasurement: boolean,
  context,
  data: XdaData,
  screenSize: ScreenSize,
  xdaLayoutOptions: XdaLayoutOptions,
  zuiRef: ZUI
): void => {
  const eventListener = (evt) => {
    // clear for next line
    if (measurementDataRef.current.dotCounter === 2) {
      clearMeasurementData(measurementDataRef, context, false);
    }
    if (xdaLayoutOptions.scale <= 0) {
      return;
    }
    const clientX = evt.clientX;
    const clientY = evt.clientY;
    const { x, y } = zuiRef.clientToSurface(clientX, clientY);

    const dot = context.makeCircle(x, y, 3);
    dot.fill = "black";
    measurementDataRef.current.dots.push(dot);
    measurementDataRef.current.dotCounter++;

    // Draw line between two dots
    if (measurementDataRef.current.dotCounter === 2) {
      const startDotX = measurementDataRef.current.dots[0].translation.x;
      const startDotY = measurementDataRef.current.dots[0].translation.y;
      const endDotX = measurementDataRef.current.dots[1].translation.x;
      const endDotY = measurementDataRef.current.dots[1].translation.y;
      measurementDataRef.current.line = context.makeLine(
        startDotX,
        startDotY,
        endDotX,
        endDotY
      );
      measurementDataRef.current.line.stroke = "black";
      measurementDataRef.current.line.linewidth = 3;
      context.add(measurementDataRef.current.line);

      if (screenSize) {
        // get distance of data from ratio between data and screenSize
        const dataToScreenSizeRatios = getDataToScreenRatio(
          data,
          screenSize,
          xdaLayoutOptions
        );
        const x1 = startDotX * dataToScreenSizeRatios[0];
        const y1 = startDotY * dataToScreenSizeRatios[1];
        const x2 = endDotX * dataToScreenSizeRatios[0];
        const y2 = endDotY * dataToScreenSizeRatios[1];

        // calculate distance
        const distanceText = getDistanceBetweenPoints(
          x1,
          y1,
          x2,
          y2,
          xdaLayoutOptions.scale
        );
        const textSize = calculateTextDimensions(
          distanceText.toString(),
          13,
          "sans-serif"
        );

        // draw text to display distance
        measurementDataRef.current.textBackground = context.makeRectangle(
          endDotX,
          endDotY + Y_OFFSET,
          textSize.width + TEXT_PADDING,
          textSize.height
        );
        measurementDataRef.current.textBackground.fill = "white";
        measurementDataRef.current.distanceText = context.makeText(
          distanceText,
          endDotX,
          endDotY + Y_OFFSET
        );
      }
    }
    context.update();
    evt.preventDefault();
  };
  if (showMeasurement) {
    context.renderer.domElement.addEventListener("click", eventListener);
    measurementDataRef.current.clickEvent = eventListener;
  }
};

// Remove all shapes from the Two.js instance and resets the refs
export function clearMeasurementData(
  measurementDataRef,
  context,
  removeClickEvent: boolean
) {
  if (measurementDataRef.current) {
    for (let i = 0; i < measurementDataRef.current.dots.length; i++) {
      const dot = measurementDataRef.current.dots[i];
      context.remove(dot);
    }
    context.remove(measurementDataRef.current.line);
    context.remove(measurementDataRef.current.distanceText);
    context.remove(measurementDataRef.current.textBackground);
    measurementDataRef.current.dotCounter = 0;
    measurementDataRef.current.dots.length = 0;
    measurementDataRef.current.line = null;
    measurementDataRef.current.distanceText = null;
    measurementDataRef.current.textBackground = null;
    if (removeClickEvent) {
      context.renderer.domElement.removeEventListener(
        "click",
        measurementDataRef.current.clickEvent
      );
      measurementDataRef.current.clickEvent = null;
    }
  }
}
